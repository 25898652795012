import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import StateOfReformMapChart from '../../components/charts/map/sor/sor-map-chart';
import ContainerBox from '../../components/container-box/container-box';
import Share from '../../components/share/share';
import SORLayout from '../../components/sor-layout/sor-layout';
import SvgIcon from '../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../components/svg-icon/svg-icon-enum';
import WhatSORMeans from '../../components/what-sor-means/what-sor-means';
import { IStateOfReformProperties } from './state-of-reform-interface';

export default class StateOfReform extends BaseComponent<IStateOfReformProperties> {
  protected declareTranslateCollection(): string {
    return 'stateOfReform';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const { dataType, routeName, pageContent } = pageContext;

    console.log('route name: ', routeName);

    return (
      <SORLayout title={pageContent.title}>
        <ContainerBox className="c-section--map" id="map">
          <div className="row c-map-details">
            <div className="col-md-8 c-map-info">
              <span>Key</span>
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10.584" cy="10.5" r="10" fill="#ADEBD4" />
                </svg>
                {pageContent.keyOne}
              </span>
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10.584" cy="10.5" r="10" fill="#2EB88A" />
                </svg>
                {pageContent.keyTwo}
              </span>
              <span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10.584" cy="10.5" r="10" fill="#248F76" />
                </svg>
                {pageContent.keyThree}
              </span>
            </div>
            <div className="col-md-4 c-map-share u-hide-mobile">
              <Share
                absoluteLink={`/state-of-reform${routeName}`}
                embeddedLink={`/embedded/sor${routeName}`}
                imageResourceFileName={`embedded-sor-${dataType}-map.png`}
              />
            </div>
          </div>
          <StateOfReformMapChart
            data={data}
            dataType={dataType}
            isTooltip={true}
          />
          <div className="c-tab__subtitle">
            <SvgIcon
              width="20"
              viewBox="0 0 20 20"
              paths={[
                { command: SvgIconPathCommand.SUBTITLE, fill: '#4B4950' },
              ]}
            />
            <span>{this.translate('mapDescription')}</span>
          </div>
          <div className="c-map-share is-full u-hide-desktop">
            <Share
              absoluteLink={`/state-of-reform${routeName}`}
              embeddedLink={`/embedded/sor${routeName}`}
              imageResourceFileName={`embedded-sor-${dataType}-map.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--todo">
          <WhatSORMeans
            items={[
              {
                title: pageContent.whatSectionOneTitle,
                description: pageContent.whatSectionOneDescription,
              },
              {
                title: pageContent.whatSectionTwoTitle,
                description: pageContent.whatSectionTwoDescription,
              },
              {
                title: pageContent.whatSectionThreeTitle,
                description: pageContent.whatSectionThreeDescription,
              },
            ]}
          />
        </ContainerBox>
      </SORLayout>
    );
  }
}

export const query = graphql`
  query GetStateOfReformData {
    all: allSheetDataSor {
      nodes {
        rcv
        state
        code
        primaries
        irc
        id
        voteAtHome
      }
    }
  }
`;
