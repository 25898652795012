import React, { Fragment, ReactChild, ReactNode } from 'react';
import { Geography } from 'react-simple-maps';
import BaseComponent from '../../../../base/base-component';
import { SORGradeColor } from '../../../../global/grade-color-enum';
import MapChart from '../map-chart';
import {
  ISORMapChartData,
  ISORMapChartProperties,
  ISORMapChartState,
} from './sor-map-chart-interface';
import SORMapChartTooltipBody from './tooltip-body/sor-map-chart-tooltip-body';

export default class SORMapChart extends BaseComponent<
  ISORMapChartProperties,
  ISORMapChartState
> {
  public constructor(properties: ISORMapChartProperties) {
    super(properties);

    this.state = {};
  }

  private getSORColorData(value: number): SORGradeColor {
    switch (value) {
      case 0:
        return SORGradeColor.LOW;
      case 1:
        return SORGradeColor.MEDIUM;
      case 2:
        return SORGradeColor.HIGH;
      default:
        return SORGradeColor.LOW;
    }
  }

  private setTooltipContent(currentState: ISORMapChartData): void {
    this.setState({
      currentStateData: currentState,
    });
  }

  private removeTooltipContent(): void {
    this.setState({
      currentStateData: undefined,
    });
  }

  public render(): ReactNode {
    const { data, isTooltip, dataType } = this.props;
    const { currentStateData } = this.state;
    const {
      all: { nodes },
    } = data;

    return (
      <MapChart
        isTooltip={isTooltip}
        tooltipBody={(): ReactChild => {
          return currentStateData ? (
            <SORMapChartTooltipBody
              currentStateData={currentStateData}
              dataType={dataType}
              removeTooltipContent={(): void => {
                this.removeTooltipContent();
              }}
            />
          ) : (
            ''
          );
        }}
      >
        {({ geographies }) =>
          geographies.map((geo) => {
            const currentState = nodes.find(
              (s) => s.state === geo.properties.name
            );

            console.log(dataType);
            return (
              <Fragment key={geo.rsmKey}>
                <Geography
                  geography={geo}
                  stroke="#fff"
                  onMouseEnter={(event): void => {
                    isTooltip && this.setTooltipContent(currentState);

                    event.currentTarget.getBoundingClientRect();
                  }}
                  onMouseLeave={(): void => {
                    isTooltip && this.removeTooltipContent();
                  }}
                  onTouchStart={(): void => {
                    this.setTooltipContent(currentState);
                  }}
                  style={{
                    default: {
                      fill: this.getSORColorData(
                        Number(currentState?.[dataType])
                      ),
                      outline: 'none',
                    },
                    hover: {
                      fill: 'current',
                      outline: 'none',
                      cursor: 'default',
                      strokeWidth: 2,
                      opacity: 1,
                      position: 'relative',
                      zIndex: 9,
                    },
                    pressed: {
                      fill: '#E42',
                      outline: 'none',
                    },
                  }}
                />
              </Fragment>
            );
          })
        }
      </MapChart>
    );
  }
}
