import React, { Fragment, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import BaseComponent from '../../base/base-component';
import { Language } from '../../global/locale/locale-handler/locale-handler-enum';
import { SORRoute } from '../../global/route-enum';
import SEO from '../seo/seo';
import SORFooter from '../sor-footer/sor-footer';
import SORHeader from '../sor-header/sor-header';
import { ISORLayoutProperties } from './sor-layout-interface';

export default class SORLayout extends BaseComponent<ISORLayoutProperties> {
  protected declareTranslateCollection(): string {
    return 'sorLayout';
  }

  public render(): ReactNode {
    const { title, children } = this.props;

    return (
      <Fragment>
        <Helmet>
          <body className="t-sor" />
        </Helmet>
        <SEO
          pageTitle={`${this.translate('siteName')} - ${title}`}
          description="Page Description"
          keywords={['state', 'reform']}
          language={Language.EN_US}
        />
        <SORHeader
          menuItems={[
            {
              title: this.translate('voteAtHome'),
              path: SORRoute.HOME,
            },
            {
              title: this.translate('rankedChoiceVoting'),
              path: SORRoute.RANKED_CHOICE_VOTING,
            },
            {
              title: this.translate('openPrimaries'),
              path: SORRoute.OPEN_PRIMARIES,
            },
            {
              title: this.translate('independentRedistricting'),
              path: SORRoute.INDEPENDENT_REDISTRICTING,
            },
          ]}
        />
        {children}
        <SORFooter
          menuItems={[
            { title: this.translate('about'), path: SORRoute.ABOUT },
            {
              title: this.translate('contact'),
              path: SORRoute.CONTACT,
            },
            {
              title: this.translate('privacyPolicy'),
              path: SORRoute.PRIVACY_POLICY,
            },
          ]}
        />
      </Fragment>
    );
  }
}
