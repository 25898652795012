import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { IMenuItem, IMenuProperties, IMenuState } from './menu-interface';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './menu.scss';

export default class Menu extends BaseComponent<IMenuProperties, IMenuState> {
  public constructor(properties: IMenuProperties) {
    super(properties);

    this.state = {
      activeItemIndex: -1,
    };
  }

  private selected = (newValue: number) => {
    this.setState({ activeItemIndex: newValue });
  };

  public componentDidMount = () => {
    this.selected(this.props.selectedItemIndex);
  };
  public render(): ReactNode {
    const { items } = this.props;
    return (
      <div className="c-tab">
        <Swiper
          modules={[Navigation]}
          spaceBetween={0}
          slidesPerView={2}
          preventClicksPropagation={false}
          preventClicks={false}
          navigation
          centerInsufficientSlides={true}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            992:{
              slidesPerView: 4,
              spaceBetween: 0,
            }
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {items.map((item: IMenuItem, index: number) => {
            const menuItemClassName: string = [
              'c-tab__items',
              this.props.isActive?.(item) ?? item.isActive ? 'is-active' : '',
            ].join(' ');
            return (
              <SwiperSlide key={index} className={menuItemClassName}>
                <Link to={item.path}>{item.title}</Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }
}
