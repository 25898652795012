import React, { ReactNode } from "react";
import BaseComponent from "../../base/base-component";
import SvgIcon from "../svg-icon/svg-icon";
import {
  IWhatSORMeansItem,
  IWhatSORMeansProperties,
} from "./what-sor-means-interface";
import './what-sor-means.scss'

export default class WhatSORMeans extends BaseComponent<IWhatSORMeansProperties> {
  protected declareTranslateCollection(): string | undefined {
    return "whatSORMeans";
  }

  public render(): ReactNode {
    const { items } = this.props;
    return (
      <div className="c-what-todo">
        <div className="c-section">
          <h2 className="c-section__title">{this.translate("whatSORMeansTitle")}</h2>
        </div>
        <ul className="c-list">
          {items.map( (item: IWhatSORMeansItem, index: number) => {
          return (
              <li className="c-list__items" key={index}>
                <h5 className="c-list__title">
                  <SvgIcon
                    width="31"
                    viewBox="0 0 31 31"
                    circles={[
                      { cx: '15.5', cy:'15.5', r: '15.5', fill: '#ADEBD4' },
                    ]}
                  />
                  {item.title}</h5>
                <p className="c-list__text">{item.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
