import { Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { Route, SORRoute } from '../../global/route-enum';
import ContainerBox from '../container-box/container-box';
import Menu from '../sor-menu/menu';
import { IMenuItem } from '../menu/menu-interface';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import { ISORHeaderProperties, ISORHeaderState } from './sor-header-interface';
import "./sor-header.scss";
export default class SORHeader extends BaseComponent<
  ISORHeaderProperties,
  ISORHeaderState
> {
  private pathname: string;

  public constructor(properties: ISORHeaderProperties) {
    super(properties);

    this.pathname = '';
    this.state = {
      isMobileNavOpen: false,
    };
  }

  protected declareTranslateCollection(): string {
    return 'stateOfReformHeader';
  }

  public componentDidMount(): void {
    this.pathname = window.location.pathname;
  }
  private onHamburgerClick(): void {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    });
  }

  public render(): ReactNode {
    const { menuItems } = this.props;
    const { isMobileNavOpen } = this.state;
    const mobileClassName: string = [
      's-nav js-nav',
      isMobileNavOpen ? 'is-open' : '',
    ].join(' ');

    return (
      <Fragment>
        <header className="o-section c-section--header">
          <div className="o-section__wrapper">
            <div className="c-header">
              <div className="c-header__col">
                <div className="c-logo">
                  <Link
                    to={SORRoute.HOME}
                    className="c-link c-link--logo"
                    aria-label="State of Democracy"
                  >
                    <SvgIcon
                      width="99"
                      viewBox="0 0 99 44"
                      paths={[
                        {
                          command: SvgIconPathCommand.STATE_OF_REFORM,
                          fill: 'white',
                        },
                      ]}
                    />
                  </Link>
                </div>
              </div>
              <div className="c-header__col">
                <div className="c-hamburger__wrap">
                  <div
                    className="c-hamburger"
                    onClick={(): void => {
                      this.onHamburgerClick();
                    }}
                  >
                    <input
                      className="c-hamburger__checkbox js-hamburger"
                      type="checkbox"
                      aria-label="Menu"
                    />
                    <span className="c-hamburger__icon"></span>
                    <span className="c-hamburger__icon"></span>
                    <span className="c-hamburger__icon"></span>
                  </div>
                </div>
                <ul className={mobileClassName}>
                  <li>
                    <Link to={Route.HOME}>
                      {this.translate('stateOfDemocracy')}
                      <SvgIcon
                        width="18"
                        viewBox="0 0 18 18"
                        paths={[
                          {
                            command: SvgIconPathCommand.STATE_OF_D_link,
                            fill: 'white',
                          },
                        ]}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>

        <ContainerBox className="c-section--tab">
          <Menu
            items={menuItems}
            selectedItemIndex={0}
            isActive={(item: IMenuItem): boolean => {
              return this.checkMenuActivity(item);
            }}
          />
        </ContainerBox>
      </Fragment>
    );
  }

  private checkMenuActivity(item: IMenuItem): boolean {
    return item.path === this.pathname || `${item.path}/` === this.pathname;
  }
}
