import React, { ReactNode } from 'react';
import BaseComponent from '../../../../../base/base-component';
import { closeSVG } from '../../../../../global/svg/close';
import { ISORMapChartTooltipBodyProperties } from './sor-map-chart-tooltip-body-interface';
import './sor-map-chart-tooltip-body.scss';

export default class SORMapChartTooltipBody extends BaseComponent<ISORMapChartTooltipBodyProperties> {
  public render(): ReactNode {
    const { currentStateData } = this.props;

    return (
      <div className="sor-map-tooltip">
        <div className="sor-map-tooltip__title">
          {currentStateData.state}
        </div>
      </div>
    );
  }
}
